<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row class="form-container rounded-lg">
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลทวีป - ประเทศ
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-select
            v-model="formData.continent"
            :items="continents"
            item-text="text"
            item-value="name"
            label="ทวีป"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-autocomplete
            v-model="formData.country"
            :items="filterCountries"
            item-text="label"
            item-value="country"
            label="ประเทศ"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            required
            :disabled="loading || !formData.continent" />
          <!-- <v-text-field
            v-model="formData.country"
            label="ประเทศ"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            required
            :disabled="loading" /> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CountriesList from 'private-country-list-js'
import CountriesProvider from '@/resources/countries.provider'
import { translateCountry, translateContinent, getContinents } from '@/utils/translates.utils'

const CountriesService = new CountriesProvider()

export default {
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    formData: {
      continent: '',
      continentCode: '',
      country: ''
    },
    continents: getContinents().map((c, i) => ({
      name: c,
      code: `${i + 1}`.padStart(2, '0'),
      text: `(${`${i + 1}`.padStart(2, '0')}) ${translateContinent(c)}`
    }))
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    },
    filterCountries () {
      if (this.formData.continent === 'America') {
        const north = CountriesList.findByContinent('North America')
          ?.map((c) => ({
            label: translateCountry(c.name.trim()),
            country: c.name,
            continent: c.continent
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label)) || []

        const south = CountriesList.findByContinent('South America')
          ?.map((c) => ({
            label: translateCountry(c.name.trim()),
            country: c.name,
            continent: c.continent
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label)) || []

        return [...north, ...south]
      }

      if (this.formData.continent === 'Australia') {
        return CountriesList.findByContinent('Oceania')
          ?.map((c) => ({
            label: translateCountry(c.name.trim()),
            country: c.name,
            continent: c.continent
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label)) || []
      }

      return CountriesList.findByContinent(this.formData.continent)
        ?.map((c) => ({
          label: translateCountry(c.name.trim()),
          country: c.name,
          continent: c.continent
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) || []
    }
  },
  mounted () {
    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'SettingsContinent' })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await CountriesService.getItemById(this.$route.params.id)

        this.formData = { ...data }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        const continent = this.continents.find((c) => c.name === this.formData.continent)
        this.formData.continentCode = continent.code

        if (!this.isEdit) {
          await CountriesService.create(this.formData)
        } else {
          await CountriesService.updateItemById(this.formData)
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'SettingsContinent' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
